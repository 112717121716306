globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"534d771ef9c31a5418fd13e1a6ff5df1a13eab8d"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// https://once-upon.sentry.io/issues/?project=5993162
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

if (isProduction) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: 'production',
    integrations: [Sentry.replayIntegration()],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.2,
    // exclude the FAQ pages from error tracking, because Kundo is the cause of the errors
    /* denyUrls: [
      /\/help\/faq/,
      /\/hjalp\/faq/,
      /\/hjelp\/vanliga-sporsmal/,
      /\/hjalp\/ofte-stillede-sporgsmal/,
      /\/hilfe\/faq/,
      /\/aide\/faq/,
      /\/aiuto\/faq/,
      /\/ayuda\/preguntas-frecuentes/,
    ], */

    // Define how likely Replay events are sampled.
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // Define how likely Replay events are sampled when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
} else if (isPreview) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: 'preview',
    integrations: [Sentry.replayIntegration()],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // Define how likely Replay events are sampled.
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // Define how likely Replay events are sampled when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
} else {
  Sentry.init({
    enabled: false,
  })
  // uncomment this if you want to enable Sentry in development, and comment out the above Sentry.init
  /* Sentry.init({
    dsn: SENTRY_DSN,
    // debug: true,
    environment: 'development',
    integrations: [Sentry.replayIntegration()],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // Define how likely Replay events are sampled.
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // Define how likely Replay events are sampled when an error occurs.
    replaysOnErrorSampleRate: 0,
  }) */
}
